import {
  isPlainObject, isInteger, pickBy, toLower,
} from 'lodash';
import eventHelpers from '@/services/helpers/event';
import Event from './event';

const UltimatePerPeriod = (perPeriod, { side }) => pickBy(
  {
    FIRST_PERIOD: perPeriod?.FIRST_PERIOD?.[side],
    SECOND_PERIOD: perPeriod?.SECOND_PERIOD?.[side],
    THIRD_PERIOD: perPeriod?.THIRD_PERIOD?.[side],
    FOURTH_PERIOD: perPeriod?.FOURTH_PERIOD?.[side],
    OVERTIME_1: perPeriod?.OVERTIME_1?.[side],
    OVERTIME_2: perPeriod?.OVERTIME_2?.[side],
  },
  isInteger,
);

const UltimateCompetitor = (event, { side, details }) => {
  const competitor = event?.[toLower(side)];
  if (!isPlainObject(competitor)) return null;

  return {
    ...competitor,
    score: details?.[`${toLower(side)}Score`] || 0,
    scorePerPeriod: UltimatePerPeriod(details?.scorePerPeriod, { side }),
  };
};

export default (data, selectedFeed) => {
  if (!isPlainObject(data)) return null;
  const event = Event(data, selectedFeed);
  const details = eventHelpers.findEventDetails(data, selectedFeed);

  return {
    ...event,
    period: details?.period || 'N_A',
    periods: details?.periods || 0,
    overtimeLengthInMinutes: details?.otLengthMinutes || 0,
    secondsLeftInPeriod: details?.secondsLeftInPeriod || 0,
    periodLengthInMinutes: details?.periodLengthMinutes || 0,
    isClockRunning: details?.clockRunning || false,
    teamInPossession: details?.teamInPossession || 'NEUTRAL',
    home: UltimateCompetitor(event, { side: 'HOME', details }),
    away: UltimateCompetitor(event, { side: 'AWAY', details }),
    inBreak: details?.eventInBreak,
    inPrematch: details?.eventPreMatch,
  };
};
