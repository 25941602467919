import {
  isPlainObject, toLower, pickBy, isInteger, map,
} from 'lodash';
import eventHelpers from '@/services/helpers/event';
import Event from './event';

const SoccerPlayer = (player) => {
  if (!isPlainObject(player)) return null;

  return {
    id: player?.playerId || '',
    name: player?.player?.personalInfo?.name || '',
    gender: player?.player?.personalInfo?.gender || 'N_A',
    state: player?.state || 'N_A',
    number: player?.number || 0,
    redCards: player?.redCards || 0,
    yellowCards: player?.yellowCards || 0,
    startsOnPitch: player?.startsOnPitch || false,
  };
};

const SoccerIncident = (incident) => {
  if (!isPlainObject(incident)) return null;

  return {
    type: incident?.type || '',
    playerId: incident?.playerId || '',
    period: incident?.period || 'N_A',
    elapsedSecondInPeriod: incident?.elapsedSecondInPeriod || 0,
    isConfirmed: incident?.isConfirmed || false,
    ownGoal: incident?.ownGoal || false,
    penalty: incident?.penalty || false,
    playerIdIn: incident?.playerIdIn || '',
    playerIdOut: incident?.playerIdOut || '',
  };
};

const SoccerCompetitor = (event, { side, details }) => {
  const competitor = event?.[toLower(side)];
  if (!isPlainObject(competitor)) return null;

  return {
    ...competitor,
    score: details?.[`${toLower(side)}Score`] || 0,
    firstLegScore: details?.[`${toLower(side)}ScoreFirstLeg`] || 0,
    scorePerPeriod: pickBy(
      {
        IN_FIRST_HALF: details?.[`${toLower(side)}ScoreFirstHalf`],
        IN_SECOND_HALF: details?.[`${toLower(side)}ScoreSecondHalf`],
        IN_EXTRATIME_FIRST_HALF: details?.[`${toLower(side)}ScoreExtraTimeFirstHalf`],
        IN_EXTRATIME_SECOND_HALF: details?.[`${toLower(side)}ScoreExtraTimeSecondHalf`],
      },
      isInteger,
    ),
    lineup: map(details?.[`${toLower(side)}Lineup`] || [], SoccerPlayer),
    substitutions: map(details?.[`${toLower(side)}Substitutions`] || [], SoccerIncident),
    yellowCards: map(details?.[`${toLower(side)}YellowCards`] || [], SoccerIncident),
    redCards: map(details?.[`${toLower(side)}RedCards`] || [], SoccerIncident),
    corners: map(details?.[`${toLower(side)}Corners`] || [], SoccerIncident),
    goals: map(details?.[`${toLower(side)}Goals`] || [], SoccerIncident),
    shotsOnTarget: map(details?.[`${toLower(side)}ShotsOnTarget`] || [], SoccerIncident),
    shotsOffTarget: map(details?.[`${toLower(side)}ShotsOffTarget`] || [], SoccerIncident),
    penalties: map(details?.[`${toLower(side)}Penalties`] || [], SoccerIncident),
    fouls: map(details?.[`${toLower(side)}Fouls`] || [], SoccerIncident),
  };
};

export default (data, selectedFeed) => {
  if (!isPlainObject(data)) return null;
  const event = Event(data, selectedFeed);
  const details = eventHelpers.findEventDetails(data, selectedFeed);

  return {
    ...event,
    period: details?.period || 'N_A',
    injuryTimeDuration: details?.injurytimeduration || 0,
    injuryTimeDurationOfficial: details?.injurytimedurationofficial || false,
    isSecondLeg: details?.secondLeg || false,
    extraTimePossible: details?.extraTimePossible || false,
    penaltiesPossible: details?.penaltiespossible || false,
    maxSubstitutions: details?.maxsubstitutions || 0,
    halfDuration: details?.halfDuration || 0,
    extraTimeHalfDuration: details?.extraTimeHalfDuration || 0,
    elapsedSecondInPeriod: details?.elapsedSecondInPeriod || 0,
    home: SoccerCompetitor(event, { side: 'HOME', details }),
    away: SoccerCompetitor(event, { side: 'AWAY', details }),
    inBreak: details?.eventInBreak,
    inPrematch: details?.eventPreMatch,
  };
};
