import {
  isPlainObject, isFinite, pickBy, toLower,
} from 'lodash';
import eventHelpers from '@/services/helpers/event';
import Event from './event';

const HockeyPerPeriod = (perPeriod, { side }) => pickBy(
  {
    FIRST_PERIOD: perPeriod?.FIRST_PERIOD?.[side],
    SECOND_PERIOD: perPeriod?.SECOND_PERIOD?.[side],
    THIRD_PERIOD: perPeriod?.THIRD_PERIOD?.[side],
    OVERTIME_1: perPeriod?.OVERTIME_1?.[side],
    OVERTIME_2: perPeriod?.OVERTIME_2?.[side],
    OVERTIME_3: perPeriod?.OVERTIME_3?.[side],
    OVERTIME_4: perPeriod?.OVERTIME_4?.[side],
    OVERTIME_5: perPeriod?.OVERTIME_5?.[side],
    OVERTIME_6: perPeriod?.OVERTIME_6?.[side],
    OVERTIME_7: perPeriod?.OVERTIME_7?.[side],
    OVERTIME_8: perPeriod?.OVERTIME_8?.[side],
    OVERTIME_9: perPeriod?.OVERTIME_9?.[side],
    OVERTIME_10: perPeriod?.OVERTIME_10?.[side],
    PENALTY_SHOOTOUT: perPeriod?.PENALTY_SHOOTOUT?.[side],
  },
  isFinite,
);

const HockeyCompetitor = (event, { side, details }) => {
  const competitor = event?.[toLower(side)];
  if (!isPlainObject(competitor)) return null;

  return {
    ...competitor,
    score: details?.[`${toLower(side)}Score`],
    scorePerPeriod: HockeyPerPeriod(details?.scorePerPeriod, { side }),
    foulsPerPeriod: HockeyPerPeriod(details?.foulsPerPeriod, { side }),
    shotsOnGoal: HockeyPerPeriod(details?.shotsOnGoal, { side }),
    shotsOffTarget: HockeyPerPeriod(details?.shotsOffTarget, { side }),
    blockedShots: HockeyPerPeriod(details?.blockedShots, { side }),
    goalsScoredOnEmptyNet: HockeyPerPeriod(details?.goalsScoredOnEmptyNet, { side }),
    powerPlayPercentage: HockeyPerPeriod(details?.powerPlayPercentage, { side }),
    totalShotsOnGoal: details?.totalShotsOnGoal?.[side],
    totalShotsOffTarget: details?.totalShotsOffTarget?.[side],
    totalBlockedShots: details?.totalBlockedShots?.[side],
    totalGoalsScoredOnEmptyNet: details?.totalGoalsScoredOnEmptyNet?.[side],
    totalPowerPlayPercentage: details?.totalPowerPlayPercentage?.[side],
  };
};

export default (data, selectedFeed) => {
  if (!isPlainObject(data)) return null;
  const event = Event(data, selectedFeed);
  const details = eventHelpers.findEventDetails(data, selectedFeed);

  return {
    ...event,
    period: details?.period || 'N_A',
    periods: details?.periods || 0,
    secondsLeftInPeriod: details?.secondsLeftInPeriod || 0,
    periodLengthInMinutes: details?.periodLengthInMinutes || 0,
    overtimeLengthInMinutes: details?.otLengthInMinutes || 0,
    isOvertimePossible: details?.otPossible || false,
    isClockRunning: details?.clockRunning || false,
    teamInPossession: details?.teamInPossession || 'NEUTRAL',
    timeOfLastClockStart: details?.timeOfLastClockStart || 0,
    home: HockeyCompetitor(event, { side: 'HOME', details }),
    away: HockeyCompetitor(event, { side: 'AWAY', details }),
    inBreak: details?.eventInBreak,
    inPrematch: details?.eventPreMatch,
    actions: details?.actions || [],
    riskFlags: details?.riskFlags || [],
  };
};
